import { useState, useCallback, useEffect, RefObject } from 'react';

const useScroll = (scrollContainer: RefObject<HTMLDivElement>, checkIsVisible?: boolean, isVisible?: boolean) => {
  const [amountScrolled, setAmountScrolled] = useState(0);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const [canScrollUp, setCanScrollUp] = useState(false);

  const updateScroll = useCallback(() => {
    if (scrollContainer.current) {
      const { clientHeight, scrollHeight, scrollTop } = scrollContainer.current;
      setAmountScrolled(scrollTop);
      setCanScrollDown(clientHeight + scrollTop + 1 < scrollHeight); // + 1 to deal with decimals at misc screen heights
      setCanScrollUp(scrollTop > 0);
    }
  }, [scrollContainer]);

  useEffect(() => {
    if (checkIsVisible && isVisible) {
      updateScroll();
    } else {
      updateScroll();
    }
  }, [checkIsVisible, isVisible, updateScroll]);

  return { amountScrolled, canScrollDown, canScrollUp, updateScroll };
};

export default useScroll;
